// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/* global ajax_object */

/**
 * Init foundation
 */
$(document).foundation();
/**
 * Responsive background
 */
var diffBg = function () {
  var $bg_foot = $('.full_width_image').data('bg');
  var $bg_foot_2 = $('.full_width_image').data('bg-2');
  if ($(window).width() <= 640) {
    $('.full_width_image').css({
      'background-image': 'url(' + $bg_foot_2 + ')',
    });
  } else {
    $('.full_width_image').css({
      'background-image': 'url(' + $bg_foot + ')',
    });
  }
};

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  diffBg();
  // doubleClickAction();
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();
  $('.practice-area').matchHeight();
  $('.logos-slider__slider li a').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */

  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
  //Read more - read less function
  $('.home-content__read-more-btn').on('click', function () {
    if ($(this).hasClass('active')) {
      $(this).text($(this).data('less'));
    } else {
      $(this).text($(this).data('more'));
    }
  });
});
//Smooth scroll functions

//Smooth scroll for practice-areas anchor
const header = document.querySelector('.header');
$("a[href='#practice-areas']").click(function () {
  $('html, body').animate(
    { scrollTop: $('#practice-areas').offset().top - header.clientHeight },
    'slow'
  );
});

//Smooth scroll for education anchor
$("a[href='#education']").click(function () {
  $('html, body').animate(
    { scrollTop: $('#education').offset().top - header.clientHeight },
    'slow'
  );
});
//Smooth scroll for jurisdiction anchor
$("a[href='#jurisdiction']").click(function () {
  $('html, body').animate(
    { scrollTop: $('#jurisdiction').offset().top - header.clientHeight },
    'slow'
  );
});
//Smooth scroll for memberships anchor
$("a[href='#memberships']").click(function () {
  $('html, body').animate(
    { scrollTop: $('#memberships').offset().top - header.clientHeight },
    'slow'
  );
});
//Smooth scroll for awards anchor
$("a[href='#awards']").click(function () {
  $('html, body').animate(
    { scrollTop: $('#awards').offset().top - header.clientHeight },
    'slow'
  );
});
//Smooth scroll for memberships & professional associations
$("a[href='#memberships-&-professional-associations']").click(function () {
  $('html, body').animate(
    {
      scrollTop:
        $('#memberships-&-professional-associations').offset().top -
        header.clientHeight,
    },
    'slow'
  );
});
//Smooth scroll for gform
$("a[href='#gform_wrapper_1']").click(function () {
  $('html, body').animate(
    { scrollTop: $('#gform_wrapper_1').offset().top },
    'slow'
  );
});
//Smooth scroll for testimonials
// $('a[role = tab]').click(function () {
//   $('html, body').animate(
//     { scrollTop: $('.testimonials__tabs-content').offset().top },
//     'slow'
//   );
// });

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here
  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here
  diffBg();
  resizeVideo();
  // doubleClickAction();
  createSpacerAfterHeader();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
/**
 * Set dynamic for search form of header
 */
const searchIcon = document.querySelector('.header__search-form-icon');
const searchForm = searchIcon.nextElementSibling.classList.contains('search')
  ? searchIcon.nextElementSibling
  : undefined;

if (searchIcon && searchForm) {
  searchIcon.addEventListener('click', () => {
    if (!searchForm.classList.contains('active')) {
      searchForm.classList.add('active');
      if (searchForm.classList.contains('hidden')) {
        searchForm.classList.remove('hidden');
      }
    } else {
      searchForm.classList.remove('active');
      searchForm.classList.add('hidden');
    }
  });
  document.addEventListener('click', (event) => {
    if (
      event.target !== searchIcon &&
      event.target.parentElement !== searchForm &&
      searchForm.classList.contains('active')
    ) {
      searchForm.classList.remove('active');
      searchForm.classList.add('hidden');
    }
  });
}

/**
 * Set dynamic for hidden content of home template
 */
const homeReadMoreBtn = document.querySelector('.home-content__read-more-btn');
const homeHiddenBottomContent = document.querySelector(
  '.home-content__hidden-bottom-content'
);
if (homeReadMoreBtn && homeHiddenBottomContent) {
  homeReadMoreBtn.addEventListener('click', (event) => {
    event.preventDefault();
    homeHiddenBottomContent.classList.toggle('visable');
    homeReadMoreBtn.classList.toggle('active');
  });
}
/**
 * Slick sliders innit
 */
$('.successes-infographic-slider').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: true,
  infinite: true,
  asNavFor: '.successes-content-slider',
});
$('.successes-content-slider').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  asNavFor: '.successes-infographic-slider',
  fade: true,
  dots: false,
  centerMode: true,
  focusOnSelect: true,
  infinite: true,
  prevArrow: $('.successes-content-slider__prev-arrow'),
  nextArrow: $('.successes-content-slider__next-arrow'),
});

$('.logos-slider__slider').slick({
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  prevArrow: $('.logos-slider__prev-arrow'),
  nextArrow: $('.logos-slider__next-arrow'),
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
});

// POPUP YOUTUBE VIDEO
$('.side-by-side-video__play-button-inner').click(function () {
  //vars
  var youtubeUrl = $('.popup-video-wrraper iframe').attr('src');
  $('.popup-video-wrraper iframe').attr('src', youtubeUrl);

  $('.side-by-side__popup-video').addClass('show');
});
$('.popup-video-wrraper .close').click(function () {
  $('.side-by-side__popup-video').removeClass('show');
  var stopVideo = function (player) {
    var vidSrc = player.prop('src').replace('autoplay=1', 'autoplay=0');
    player.prop('src', vidSrc);
  };

  stopVideo($('.popup-video-wrraper iframe'));
});
const logoSliderLinks = document.querySelectorAll('.logos-slider__slider li a');
Array.from(logoSliderLinks).forEach((elem) => {
  if (elem.getAttribute('href') === '#' || elem.getAttribute('href') === '') {
    elem.classList.add('without-href');
    elem.addEventListener('click', (event) => event.preventDefault());
  }
});
const singleAtterneyFirstAnchor = document.querySelector(
  '.single-atterney__first-anchor'
);
if (singleAtterneyFirstAnchor) {
  singleAtterneyFirstAnchor.addEventListener('click', (event) => {
    event.preventDefault();
  });
}

const bodyElem = document.body;
const headerMenuIcon = document.querySelector('.menu-icon');
if (header && headerMenuIcon) {
  headerMenuIcon.addEventListener('click', () => {
    if (headerMenuIcon.classList.contains('is-active')) {
      header.classList.add('menu-active');
      bodyElem.classList.add('menu-active');
    } else if (header.classList.contains('menu-active')) {
      header.classList.remove('menu-active');
      bodyElem.classList.remove('menu-active');
    }
  });
}

const headerFormAnchor = document.querySelector('.title-bar__form-anchor');
if (headerFormAnchor) {
  let anchorHref = headerFormAnchor.getAttribute('href');
  if (anchorHref.length > 1 && anchorHref[0] === '#') {
    anchorHref = anchorHref.split('');
    anchorHref.splice(0, 1);
    anchorHref = anchorHref.join('');
  }
  let form = document.getElementById(anchorHref);
  if (form) {
    headerFormAnchor.classList.add('has-form-on-page');
  } else if (headerFormAnchor.classList.contains('has-form-on-page') && !form) {
    headerFormAnchor.classList.remove('has-form-on-page');
  }
}
//
// function doubleClickAction() {
//   const elems = document.querySelectorAll('.practice-areas__practice-area');
//   let intViewportWidth = window.innerWidth;
//   if (intViewportWidth < 1024 && elems) {
//     Array.from(elems).forEach((elem) => {
//       elem.addEventListener('click', prevFirstClick);
//     });
//   }
// }
//
// function prevFirstClick(e) {
//   e.preventDefault();
//   e.target.removeEventListener('click', prevFirstClick);
// }

function createSpacerAfterHeader() {
  const header = document.querySelector('.header');
  const headerHeight = header.clientHeight;
  const headerSmall = 70;
  const sectionSpacer = document.querySelector('.section-spacer');
  if (!sectionSpacer) {
    const elemAfterHeader = document.createElement('div');
    elemAfterHeader.classList.add('section-spacer');
    header.after(elemAfterHeader);
    elemAfterHeader.style.height = headerHeight - headerSmall + 'px';
  } else {
    sectionSpacer.style.height = headerHeight + 'px';
  }
}

createSpacerAfterHeader();

$(document).on('ready', function () {
  const ctaBar = $('.header__cta');

  function hideCTABar() {
    ctaBar.hide();
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 30);
    document.cookie =
      'ctaAcknowledged=true; expires=' + expiryDate.toUTCString() + '; path=/';
  }

  function checkCTAAcknowledgement() {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith('ctaAcknowledged=')) {
        hideCTABar();
        return;
      }
    }
    ctaBar.show();
  }

  $('.header__cta a').on('click', function (e) {
    e.preventDefault();
    hideCTABar();
  });

  checkCTAAcknowledgement();

  // media filter

  // Category Filter

  $('.js-media-pagination .page-numbers').click(function (e) {
    $('html, body').animate(
      {
        scrollTop: $('html').offset().top + 100,
      },
      500
    );
    e.preventDefault();
    console.log('test1');
    filterMedia('-1', $(this).attr('href'));
  });

  function filterMedia(category = '', paged = 1) {
    let data = {
      action: 'filter_media',
      paged: paged,
      // eslint-disable-next-line no-undef
      nonce: ajax_object.nonce,
    };
    $('.faqs-list-js').fadeOut();
    // eslint-disable-next-line no-undef
    $.post(ajax_object.ajax_url, data, function (response) {
      if (response) {
        $('.faqs-list-js').html(response);
        $('.faqs-list-js').addClass('ajax-js');
        $('.ajax-js .archive-pagination .page-numbers').click(function (e) {
          $('html, body').animate(
            {
              scrollTop: $('html').offset().top + 100,
            },
            500
          );
          e.preventDefault();
          filterMedia(category, $(this).attr('href'));
        });
        $('.faqs-list-js').fadeIn();
      }
    });
  }

  // Category Filter
  $('#faq-cat').change(function () {
    let dropdown = document.getElementById('faq-cat');
    if (dropdown.options[dropdown.selectedIndex].value) {
      let category = dropdown.options[dropdown.selectedIndex].value;
      filterFaqs(category);
    }
  });

  $('.js-faq-pagination .page-numbers').click(function (e) {
    $('html, body').animate(
      {
        scrollTop: $('html').offset().top + 100,
      },
      500
    );
    e.preventDefault();
    filterFaqs('-1', $(this).attr('href'));
  });

  function filterFaqs(category = '', paged = 1) {
    let data = {
      action: 'filter_faqs',
      category: category,
      paged: paged,
      // eslint-disable-next-line no-undef
      nonce: ajax_object.nonce,
    };
    $('.faqs-list-js').fadeOut();
    // eslint-disable-next-line no-undef
    $.post(ajax_object.ajax_url, data, function (response) {
      if (response) {
        $('.faqs-list-js').html(response);
        $('.faqs-list-js').addClass('ajax-js');
        $('.ajax-js .archive-pagination .page-numbers').click(function (e) {
          $('html, body').animate(
            {
              scrollTop: $('html').offset().top + 100,
            },
            500
          );
          e.preventDefault();
          filterFaqs(category, $(this).attr('href'));
        });
        $('.faqs-list-js').fadeIn();
      }
    });
  }

  // Category Filter Video
  $('#cat-custom').change(function () {
    console.log(this);
    var dropdown = document.getElementById('cat-custom');
    if (dropdown.options[dropdown.selectedIndex].value) {
      var category = dropdown.options[dropdown.selectedIndex].value;
      filterVideos(category);
    }
  });

  $('.js-video-pagination .page-numbers').click(function (e) {
    $('html, body').animate(
      {
        scrollTop: $('html').offset().top + 100,
      },
      500
    );
    e.preventDefault();
    filterVideos('-1', $(this).attr('href'));
  });

  function filterVideos(category = '', paged = 1) {
    let data = {
      action: 'filter_video_gallery',
      category: category,
      paged: paged,
    };
    category = $(this).data('category');
    $('.faqs-list-js').fadeOut();
    $.post(ajax_object.ajax_url, data, function (response) {
      if (response) {
        $('.faqs-list-js').html(response);
        $('.faqs-list-js').addClass('ajax-js');
        $('.ajax-js .archive-pagination .page-numbers').click(function (e) {
          $('html, body').animate(
            {
              scrollTop: $('html').offset().top + 100,
            },
            500
          );
          e.preventDefault();
          filterVideos(category, $(this).attr('href'));
        });
        $('.faqs-list-js').fadeIn();
      }
    });
  }

  // Default Posts
  $('#cat').change(function () {
    let dropdown = document.getElementById('cat');
    if (dropdown.options[dropdown.selectedIndex].value) {
      let category = dropdown.options[dropdown.selectedIndex].value;
      filterDefault(category);
    }
  });

  $('.js-faq-pagination .page-numbers').click(function (e) {
    $('html, body').animate(
      {
        scrollTop: $('html').offset().top + 100,
      },
      500
    );
    e.preventDefault();
    filterDefault('-1', $(this).attr('href'));
  });

  function filterDefault(category = '', paged = 1) {
    let data = {
      action: 'filter_dafault_posts',
      category: category,
      paged: paged,
      // eslint-disable-next-line no-undef
      nonce: ajax_object.nonce,
    };
    $('.faqs-list-js').fadeOut();
    // eslint-disable-next-line no-undef
    $.post(ajax_object.ajax_url, data, function (response) {
      if (response) {
        $('.faqs-list-js').html(response);
        $('.faqs-list-js').addClass('ajax-js');
        $('.ajax-js .archive-pagination .page-numbers').click(function (e) {
          $('html, body').animate(
            {
              scrollTop: $('html').offset().top + 100,
            },
            500
          );
          e.preventDefault();
          filterDefault(category, $(this).attr('href'));
        });
        $('.faqs-list-js').fadeIn();
      }
    });
  }
});

$('main a[href*=#]').click(function (event) {
  $('html, body').animate(
    {
      scrollTop: $($.attr(this, 'href')).offset().top,
    },
    500
  );
  event.preventDefault();
});
